"use client";
import { I18nInstance } from "modules/common/common/utils/i18n-utils";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouterInstance } from "router/router-utils";
import { exitB2b, selectEntered } from "store/sessionStorage/slices/b2bSlice";

export default function Template({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const dispatch = useDispatch();
  const entered = useSelector(selectEntered);
  const { i18n } = useTransContent();
  const router = useRouter();

  useEffect(() => {
    I18nInstance.setI18nInstance(i18n);
  }, [i18n]);

  useEffect(() => {
    RouterInstance.setRouterInstance(router);
  }, [router]);

  useEffect(() => {
    if (entered) {
      dispatch(exitB2b());
    }
  }, [dispatch]);

  return children;
}
